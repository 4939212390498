import React from 'react'

class ProjectComponent extends React.Component {
    constructor(props: any) {
        super(props);
    }


    render() {
        return (
            <div >
            </div>
        )
    }
}

// const authCondition = (authUser: any) => !!authUser;

export const Project = ProjectComponent;