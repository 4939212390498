import React from 'react';

import HeaderNavNonAuth from './HeaderNavNonAuth';

const HeaderNav = () => {

    return (
        <HeaderNavNonAuth />
    );
}

export default HeaderNav;